// @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,300&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

a {
  text-decoration: none;
  color: #ccc;
}

body {
  max-width: 100% !important;
  // ::-webkit-scrollbar {
  //   display: none;
  //   scroll-behavior: smooth;
  // }

  // display: flex;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  // font-family: 'Lato', sans-serif;
  // font-family: 'Roboto Slab', serif;
  font-family: "Montserrat", sans-serif;
}

.searchbar-input {
  --border-radius: 12px !important;
  box-shadow: none !important;
}
~ element.style {
  transform: translateY(68px);
}

.filepond--root .filepond--credits[style] {
  display: none !important;
}

.nav-home {
  background-color: #6a64ff;
  z-index: 999;
  height: 70px;
  line-height: 70px;
  padding: 0px 20px;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
}

.nav {
  height: 70px;
  line-height: 70px;
  box-shadow: 2px 4px 3px #ddd;
  padding: 0px 20px;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: #fff;
}
.content {
  margin-top: 70px;
}
.nav .nav-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.my-custom-class {
  --max-width: 600px;
}

.buy-modal {
  --max-height: 300px;
}

.buyWithEth {
  display: flex;
  justify-content: space-around;

  h4 {
    font-weight: 700;
  }
}

.pool-p10 {
  padding: 10px;
  flex-grow: 1;
}

.sb {
  --border-radius: 30px;
  --background: #cacce3;
  --background-checked: #6470cf;
}

.sc-ion-searchbar-md-h {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: unset;
  padding-inline-start: unset;
  -webkit-padding-end: unset;
  padding-inline-end: unset;
}
@media (max-width: 600px) {
  .pool-p10 {
    padding: 10px;
  }
}

ion-header::after {
  background-image: none;
}

ion-button {
  --border-radius: 4px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-wrapper.sc-ion-modal-md,
.modal-shadow.sc-ion-modal-md {
  --webkit-box-shadow: none !important;
  --box-shadow: none !important;
  --background: transparent !important;
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.nav-avata {
  .div {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;

    &::after {
      content: "";
      height: 100px;
      width: 70px;
      position: absolute;
      background-color: transparent;
      top: 50px;
      right: 10px;
    }
  }
  &:hover {
    .div {
      visibility: visible;
      opacity: 1;
    }
  }
}

.alert {
  padding: 20px 10px;
  margin: 50px 5px;
  width: fit-content;
  &.alert-info {
    border: 1px solid #6c61fe;
    border-radius: 5px;
    background: #6a64ff5c;
  }
}
